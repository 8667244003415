import { postService, getService, bannerService } from "../commonServices";

export class SuperadminService {
  /* Admins */
  getAdmins(page,perPage,status='', keyword='') {
    let url = `/superadmin/admins?page=${page}&perPage=${perPage}&status=${status}&keyword=${keyword}`;
    let data = getService(url);
    return data;
  }

  getAdmin(id) {
    let url = `/admin/${id}`;
    let data = getService(url);
    return data;
  }

  /* Users */
  getUsers(page,perPage,status='', keyword='') {
    let url = `/superadmin/users?page=${page}&perPage=${perPage}&status=${status}&keyword=${keyword}`;
    let data = getService(url);
    return data;
  }

  /* Products */
  getProducts({ page, perPage, keyword = '', createdAt = '', updatedAt = '', status = '', price = '', outofstock = '' }) {
    let url = `/superadmin/products?page=${page}&perPage=${perPage}&createdAt=${createdAt}&price=${price}&updatedAt=${updatedAt}&status=${status}&keyword=${keyword}&outofstock=${outofstock}`;
    let data = getService(url);
    return data;
  }

  approveProduct(product_slug) {
    let url = `/superadmin/approve-product/${product_slug}`;
    let data = postService(url, undefined, 'PATCH');
    return data;
  }

  disApproveProduct(product_slug, comment='') {
    const body = JSON.stringify({ comment });
    let url = `/superadmin/disapprove-product/${product_slug}`;
    let data = postService(url, body, 'PUT');
    return data;
  }

  toggleProductFeatured(product_slug) {
    let url = `/superadmin/featured-product/${product_slug}`;
    let data = postService(url, undefined, 'PUT');
    return data;
  }

  /* Banner */
  createBanner(formData){
    let data = bannerService('/superadmin/banner/', formData);
    return data;
  }

  getBanners(page,perPage,status='', keyword='') {
    let url = `/superadmin/banner?page=${page}&perPage=${perPage}&status=${status}&keyword=${keyword}`;
    let data = getService(url);
    return data;
  }

  deleteBanner(id) {
    const body = JSON.stringify({ banner_id:id });
    let url = `/superadmin/banner/`;
    let data = postService(url, body, 'PATCH');
    return data;
  }
}
