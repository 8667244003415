import React from 'react'
import Layout from '../../../core/Layout'
import BannerForm from './BannerForm'

const index = () => {
    return (
        <Layout>
           <BannerForm/>
        </Layout>
    )
}


export default React.memo(index)