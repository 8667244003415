import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Table as AntdTable, Input, Button, Space, Modal, Avatar, Drawer, Popconfirm } from 'antd';
import Highlighter from 'react-highlight-words';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBanners, deleteBanner } from '../../../../redux/actions/superadmin_action';

const Table = ({ multiLoading, banners, totalCount, getBanners, deleteBanner }) => {
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
        total: 0,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        showQuickJumper: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    });

    const [preview, setPreview] = useState({
        previewVisible: false,
        previewImage: '',
        previewTitle: ''
    });

    const searchInput = useRef(null);

    useEffect(() => {
        getBanners(pagination.current, pagination.pageSize);
    }, [pagination.current, pagination.pageSize]);

    useEffect(() => {
        setPagination({ ...pagination, total: totalCount });
    }, [totalCount]);

    const handleTableChange = (pagination, filters) => {
        setPagination({ ...pagination, current: pagination.current, pageSize: pagination.pageSize });
        getBanners(pagination.current, pagination.pageSize);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = clearFilters => {
        clearFilters();
    };

    const getBannerSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8, backgroundColor: '#495057' }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Cancelar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#495057' }} />,
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
    });

    const openPreview = async file => {
        setPreview({
            previewImage: `${process.env.REACT_APP_SERVER_URL}/uploads/${file.bannerPhoto}`,
            previewVisible: true,
            previewTitle: file.bannerPhoto.substring(file.bannerPhoto.lastIndexOf('/') + 1),
        });
    };

    const handleCancel = () => setPreview({ ...preview, previewVisible: false });

    const removeBanner = async (id) => {
        await deleteBanner(id)
        
    };

    const columns = useMemo(() => [
        {
            title: 'Descrição',
            dataIndex: 'description',
            key: 'description',
            width: '20%',
            render: description => `${description}`
            //...getBannerSearchProps('description')
        },
        // {
        //     title: 'Imagem',
        //     dataIndex: 'bannerPhoto',
        //     key: 'bannerPhoto',
        //     width: '20%',
        //     render: (text, record) => (
        //         <Button onClick={() => openPreview(record)}>
        //             Visualizar Imagem
        //         </Button>
        //     ),
        // },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            width: '20%',
            // ...getBannerSearchProps('link')
            render: link => `${link}`
        },
        {
            title: 'Data de Criação',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '20%',
            render: date => `${moment(date).format("DD/MM/YYYY")}`
        },
        {
            title: 'Ação',
            dataIndex: '',
            width: '10%',
            render:  (text, record) => (
                <>
                    <button onClick={e => openPreview(record)} className="btn btn-info btn-sm"><i className="fas fa-eye"></i></button>
                    <Popconfirm
                        title="Tem certeza de que deseja excluir este banner?"
                        onConfirm={() => removeBanner(record._id)}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <button className="btn btn-danger btn-sm"><i className="fas fa-trash"></i></button>
                    </Popconfirm>
                </>
            ),
        },
    ], []);

    return (
        <>
            <AntdTable
                columns={columns}
                rowKey={record => record._id}
                dataSource={banners}
                pagination={pagination}
                loading={multiLoading}
                onChange={handleTableChange}
                size='small'
            />
            <Modal
                visible={preview.previewVisible}
                title={preview.previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img
                    alt="banner"
                    style={{ width: '100%' }}
                    src={preview.previewImage}
                />
            </Modal>
        </>
    );
};

Table.propTypes = {
    multiLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    getBanners: PropTypes.func.isRequired,
    deleteBanner: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    banners: state.superadmin.banners,
    multiLoading: state.superadmin.loading,
    totalCount: state.superadmin.totalCount,
});

export default connect(mapStateToProps, { getBanners, deleteBanner })(Table);