import React from 'react';
import Layout from '../../core/Layout'

const NotFound = () => {
  return (
    <Layout>
      <div className='text-center'>
        <h1>404 - Não encontrado</h1>
        <p>Desculpe, a página que você procura não existe.</p>
      </div>
    </Layout>
  );
};

export default NotFound;
