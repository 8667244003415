import React, { useMemo } from 'react'
import SideBar from '../../common/SideBar'

const AdminBar = ({adminProfile}) => {
    const titles = useMemo(()=>[
    {
        key:'admin',
        icon:'sliders',
        main: 'Dashboard',
        sub:[{
            name:'Gráficos',
            path:'/'
        }]
    }, 
    {
        key:'product',
        icon:'shopping-bag',
        main: 'Produto',
        sub: [{
            name: 'Adicionar Produto',
            path: '/add-product'
        }, {
            name: 'Gestão de Produto',
            path: '/manage-products'
        }]
    },
    {
        key:'orders',
        icon: 'layout',
        main: 'Pedidos',
        sub: [ {
            name: 'Gestão de Pedidos',
            path: '/order'
        }]
    }
    ],[])

    return (
        <SideBar titles={titles} adminProfile={adminProfile}/>
    )
}

export default (AdminBar)
