import { GLOBAL_ERROR, UPDATE_BANK_TYPES, UPDATE_USER, GET_BANK_INFO, UPDATE_ADMIN_TYPES, GET_WAREHOUSE_INFO} from "../types";
import api from "../../utils/api";
import { finish, init, success, error, successMessage } from "../commonActions";
import { ProfileService } from "../api/profile_api";

const profileService = new ProfileService();
export const updateProfile = (profile, id) => async (dispatch) => {
  dispatch(init(UPDATE_ADMIN_TYPES.UPDATE_ADMIN));

  const response = await profileService.updateProfile(profile,id);

  dispatch(finish(UPDATE_ADMIN_TYPES.UPDATE_ADMIN));

  if (response.isSuccess) {
    dispatch(successMessage("Perfil Atualizado."));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
  try {
    profile = JSON.stringify(profile);
    const res = await api.put(`/admin/${id}`, profile);
    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });
    return true;
  } catch (err) {
    console.log("****profile_actions/updateProfile****", err);
    dispatch({ type: GLOBAL_ERROR, payload: err || "Not Found" });

    return false;
    //error handling here..
  }
};

export const updateBusinessInfo = (profile, id) => async (dispatch) => {

  console.log("oi")
  // dispatch(init(UPDATE_ADMIN_TYPES.UPDATE_ADMIN));

  // const response = await profileService.updateProfile(profile,id);

  // dispatch(finish(UPDATE_ADMIN_TYPES.UPDATE_ADMIN));

  // if (response.isSuccess) {
  //   dispatch(success(UPDATE_ADMIN_TYPES.UPDATE_ADMIN, response.data));
  // } else if (!response.isSuccess) {
  //   dispatch(error(response.errorMessage));
  // }
  // try {
  //   profile = JSON.stringify(profile);
  //   const res = await api.put(`/admin/${id}`, profile);
  //   dispatch({
  //     type: UPDATE_USER,
  //     payload: res.data,
  //   });
  //   return true;
  // } catch (err) {
  //   console.log("****profile_actions/updateProfile****", err);
  //   dispatch({ type: GLOBAL_ERROR, payload: err || "Not Found" });

  //   return false;
  //   //error handling here..
  // }
};

/* Bank */
export const getAdminBank = (id) => async (dispatch) => {
  try {
    const response = await profileService.getAdminBank(id);
    dispatch({
      type: GET_BANK_INFO,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: GLOBAL_ERROR, payload: err || "Not Found" });
  }
};

export const updateAdminBank = (body, id) => async (dispatch) => {
  dispatch(init(UPDATE_BANK_TYPES.UPDATE_BANK));

  const response = await profileService.updateBank(body, id);

  dispatch(finish(UPDATE_BANK_TYPES.UPDATE_BANK));
  
  if (response.isSuccess) {
    dispatch(successMessage("Informações Bancárias atualizadas."));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const createFileBank = (formData, id) => async (dispatch) => {
  const response = await profileService.createFileBank(formData, id);

  if (response.isSuccess) {
    dispatch(successMessage("Arquivo inserido com sucesso!"));
    return response;
  }else {
    dispatch(error(response.errorMessage));
  }
};

export const deleteFileBank = (body, id) => async (dispatch) => {
  // dispatch(init(UPDATE_BANK_TYPES.UPDATE_BANK));

  // const response = await profileService.updateBank(body, id);

  // dispatch(finish(UPDATE_BANK_TYPES.UPDATE_BANK));
  
  // if (response.isSuccess) {
  //   dispatch(successMessage("Informações Bancárias atualizadas."));
  // } else if (!response.isSuccess) {
  //   dispatch(error(response.errorMessage));
  // }
};

/* Warehouse */
export const getAdminWarehouse = (id) => async (dispatch) => {
  try {
    const response = await profileService.getAdminWarehouse(id);
    dispatch({
      type: GET_WAREHOUSE_INFO,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: GLOBAL_ERROR, payload: err || "Not Found" });
  }
};

export const updateAdminWarehouse = (body, id) => async (dispatch) => {
  dispatch(init(UPDATE_BANK_TYPES.UPDATE_BANK));

  const response = await profileService.updateWarehouse(body, id);

  dispatch(finish(UPDATE_BANK_TYPES.UPDATE_BANK));
  
  if (response.isSuccess) {
    dispatch(successMessage("Informações do Armazém atualizadas."));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const createFileWarehouse = (formData, id) => async (dispatch) => {
  const response = await profileService.createFileWarehouse(formData, id);

  if (response.isSuccess) {
    dispatch(successMessage("Arquivo inserido com sucesso!"));
    return response;
  }else {
    dispatch(error(response.errorMessage));
  }
};

export const deleteFileWarehouse = (body, id) => async (dispatch) => {
  // dispatch(init(UPDATE_BANK_TYPES.UPDATE_BANK));

  // const response = await profileService.updateBank(body, id);

  // dispatch(finish(UPDATE_BANK_TYPES.UPDATE_BANK));
  
  // if (response.isSuccess) {
  //   dispatch(successMessage("Informações Bancárias atualizadas."));
  // } else if (!response.isSuccess) {
  //   dispatch(error(response.errorMessage));
  // }
};