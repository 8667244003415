import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import {
    Button,
    Form,
    Input,
    Col,
    Row
} from "antd";
const PriceAndStock = ({ layout, prev, submitProductInfo, loading, priceAndStockFormData }) => {
    const [form] = Form.useForm()
    useEffect(() => {
        form.setFieldsValue({ ...priceAndStockFormData })
    }, [priceAndStockFormData])
    const onFinish = (values) => {
        submitProductInfo(values)
    };
    const onSubmit = () => {
        form.submit()
    }

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    
    return (
        <>
            <Form
                {...layout}
                name="price_and_stock"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                
                <Form.Item
                    label="Preço de venda"
                    name="price"
                    rules={[
                        {
                            type: 'string',
                            // required: true,
                            message: "Por favor informe o preço de venda!",
                        }
                    ]}
                >
                    <Input type='number' style={{ width: '100%' }} placeholder="2000" />
                </Form.Item>

                <Form.Item
                    label="Taxa de desconto"
                    name="discountRate"
                    rules={[
                        {
                            type: 'string',
                            // required: true,
                            message: "Por favor informe a taxa de desconto!",
                        }
                    ]}
                >
                    <Input type='number' style={{ width: '100%' }} placeholder="15.6" />
                </Form.Item>

                <Form.Item
                    label="Quantidade em estoque"
                    name="quantity"
                    rules={[
                        {
                            type: 'string',
                            // required: true,
                            message: "Por favor informe a quantidade em estoque!",
                        }
                    ]}
                >
                    <Input type='number' style={{ width: '100%' }} placeholder="100" />
                </Form.Item>
            </Form>
            <div className="steps-action">
                <Button style={{ margin: "0 8px" }} onClick={() => prev(form.getFieldsValue())}>
                    Voltar
                </Button>
                <Button disabled={loading} type="primary" onClick={onSubmit}>
                    Salvar Edição
                </Button>
            </div>
        </>

    );
};

PriceAndStock.propTypes = {
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) =>  ({
    loading: state.product.addProductLoading
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PriceAndStock))