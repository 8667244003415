import React from 'react';
import Layout from '../../core/Layout'

const UnderMaintenance = () => {
  return (
    <Layout>
      <div className='text-center'>
        <h1>Página em manutenção!</h1>
        <p>Desculpe, volte mais tarde.</p>
      </div>
    </Layout>
  );
};

export default UnderMaintenance;
