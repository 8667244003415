import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateAdminWarehouse, getAdminWarehouse, createFileWarehouse } from '../../../redux/actions/profile_actions';
import { message, Form, Input, Button } from 'antd';

const WarehouseForm = ({ warehouse, updateAdminWarehouse, getAdminWarehouse, createFileWarehouse, user }) => {
    const [adminWarehouse, setAdminWarehouse] = useState({
        ownerName: '',
        address: '',
        city: '',
        phoneNumber: '',
        proofOfAddress: null,
    });
    const [isDisable, setIsDisabled] = useState(false);
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        getAdminWarehouse(user._id);
    }, [user]);

    useEffect(() => {
        setAdminWarehouse({
            ...adminWarehouse,
            ownerName: warehouse?.ownerName || '',
            address: warehouse?.address || '',
            city: warehouse?.city || '',
            phoneNumber: warehouse?.phoneNumber || '',
            proofOfAddress: null,
        });
    }, [warehouse]);

    const { ownerName, address, city, phoneNumber, proofOfAddress } = adminWarehouse;

    const onChange = (e) =>
        setAdminWarehouse({
            ...adminWarehouse,
            [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value,
        });

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile !== undefined) {
            setFileName(selectedFile.name);
            setAdminWarehouse({
                ...adminWarehouse,
                proofOfAddress: selectedFile,
            });
        } else {
            setFileName(null);
            setAdminWarehouse({
                ...adminWarehouse,
                proofOfAddress: null,
            });
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!adminWarehouse.proofOfAddress) {
            message.error('Insira a imagem do comprovante de endereço!');
            return;
        }

        const isJpgOrPng = adminWarehouse.proofOfAddress.type === 'image/jpeg' || adminWarehouse.proofOfAddress.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Você só pode enviar arquivos JPG/PNG!');
            return;
        }

        setIsDisabled(true);

        try {
            const formData = new FormData();
            formData.append('doc', adminWarehouse.proofOfAddress);
            const result = await createFileWarehouse(formData, user._id);

            console.log(result)
            if (result !== undefined) {
                adminWarehouse.proofOfAddress = result.data.proofOfAddress._id;
                const body = JSON.stringify(adminWarehouse);
                await updateAdminWarehouse(body, user._id);
            }
        } catch (error) {
            message.error(error);
        }

        setIsDisabled(false);
    };

    return (
        <div className="col-md-12">
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Endereço do Armazém</h5>
                    <h6 className="card-subtitle text-muted">Deve ser o endereço de despacho/retorno.</h6>
                </div>
                <div className="card-body">
                    <form onSubmit={onSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="ownerName">Nome do Proprietário</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="ownerName"
                                    placeholder="Nome do proprietário"
                                    name="ownerName"
                                    value={ownerName}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="address">Endereço</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    placeholder="Endereço"
                                    name="address"
                                    value={address}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="city">Cidade</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    placeholder="Cidade"
                                    name="city"
                                    value={city}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="phoneNumber">Número de Telefone</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="phoneNumber"
                                    placeholder="Número de telefone"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="proofOfAddress">Comprovante de Endereço</label>
                                <input
                                    type="file"
                                    className="form-control-file"
                                    id="proofOfAddress"
                                    name="proofOfAddress"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={isDisable}>
                            Enviar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

WarehouseForm.propTypes = {
    warehouse: PropTypes.object,
    updateAdminWarehouse: PropTypes.func.isRequired,
    createFileWarehouse: PropTypes.func.isRequired,
    getAdminWarehouse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    warehouse: state.profile.warehouse,
});

export default connect(mapStateToProps, { updateAdminWarehouse, getAdminWarehouse, createFileWarehouse })(WarehouseForm);

// import React from 'react'
// import PropTypes from 'prop-types'



// const WarehouseForm = props => {
   
//     return (
//         <div className="col-md-12">
//             <div className="card">
//                 <div className="card-header">
//                     <h5 className="card-title">Warehouse Address</h5>
//                     <h6 className="card-subtitle text-muted">It should be dispatcher/return address.</h6>
//                 </div>
//                 <div className="card-body">
//                     <form>
//                         <div className="form-row">
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="inputEmail4">Owner Name</label>
//                                 <input type="text" className="form-control" id="inputEmail4" placeholder="name" />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="add">Adderss</label>
//                                 <input type="text" className="form-control" id="add" placeholder="adderss" />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="dist">City</label>
//                                 <input type="text" className="form-control" id="dist" placeholder="city" />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="inputPassword4">Phone Number</label>
//                                 <input type="text" className="form-control" id="inputPassword4" placeholder="Phone number" />
//                             </div>
//                         </div>
//                         <button type="submit" className="btn btn-primary">Submit</button>
//                     </form>
//                 </div>
//             </div>
//         </div>

//     )
// }

// WarehouseForm.propTypes = {

// }

// export default WarehouseForm
