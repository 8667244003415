import React from 'react'
import Layout from '../../core/Layout'
import Products from './Products'

export const index = () => {
    return (
        <Layout>
            <Products/>
        </Layout>

    )
}



export default (index)
