import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Carousel, Tabs } from 'antd';
import { SideBySideMagnifier } from "react-image-magnifiers";
import parse from 'html-react-parser';
import ProductStatus from './ProductStatus'
const { TabPane } = Tabs


export const ProductDetail = ({product, singleLoading, isSuperadmin}) => {
    const contentStyle = {
        height: '170px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };
    return (
        <div className="column">
            <div className="my-4 text-center">
                <h2 className="text-center">{product?.name}</h2>
            </div>
            <div className="row">
                <div className="col-xl-7">
                    <Carousel>
                        {
                            product?.images.map(image => (

                                <div key={image._id}>
                                    <h3 style={contentStyle}>
                                        <SideBySideMagnifier
                                            style={{ height: '100%', width: '100%' }}
                                            alwaysInPlace={true}
                                            imageSrc={`${process.env.REACT_APP_SERVER_URL}/uploads/${image.medium}`}
                                            imageAlt="Example"
                                        // largeImageSrc={`${process.env.REACT_APP_SERVER_URL}/uploads/${image.large}`} // Optional
                                        />
                                    </h3>
                                </div>
                            ))
                        }
                    </Carousel>
                </div>
                <div className="col-xl-4">
                    <div className="mb-2">
                        <p><strong>Marca:</strong> {product?.brand?.brandName}</p>
                        <p><strong>categoria:</strong> {product?.category?.map(c=>c.displayName).join(', ')}</p>
                        <p><strong>Slug:</strong> {product?.slug}</p>
                        <p><strong>tags:</strong> {product?.tags?.join(', ')}</p>
                        <ProductStatus isSuperadmin={isSuperadmin} product={product}/>
                    </div>
                </div>
            </div>
            <div className="row mb-12">
                <div className="col-md-12">
                    <div className="my-2">
                        <h6>Destaques:</h6>
                    </div>
                    {
                        product && parse(product?.highlights)
                    }
                    <hr className="my-2"/>
                    <div className="my-2">
                        <h6>Detalhes:</h6>
                    </div>
                    <Tabs >
                        <TabPane tab="Descrição" key="a">
                            {product && parse(product?.description)}
                        </TabPane>
                        <TabPane tab="Outros" key="b">
                            Informações de vendas
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

ProductDetail.propTypes = {
    product: PropTypes.object,
    singleLoading: PropTypes.bool,
    isSuperadmin:PropTypes.bool,
}

const mapStateToProps = (state) => ({
    product: state.product.product,
    singleLoading: state.product.singleLoading
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail)
