import React from 'react'
import Products from '../Product/index'
import UnderMaintenance from '../../UnderMaintenance'

export const index = () => {
    return (
      
        <>
         <UnderMaintenance/>
        </>
       
       

    )
}



export default (index)
