import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateAdminBank, getAdminBank, createFileBank } from '../../../redux/actions/profile_actions';
import { Modal, message, Form, Input, Button } from 'antd';

const BankForm = ({ bank, updateAdminBank, getAdminBank, createFileBank, user }) => {
    const [adminBank, setAdminBank] = useState({
        accountHolder: '',
        bankName: '',
        branchName: '',
        accountNumber: '',
        routingNumber: '',
        chequeCopy: null,
    });
    const [isDisable, setIsDisabled] = useState(false);
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        getAdminBank(user._id);
    }, [user]);

    useEffect(() => {
        setAdminBank({
            ...adminBank,
            accountHolder: bank?.accountHolder || '',
            bankName: bank?.bankName || '',
            branchName: bank?.branchName || '',
            accountNumber: bank?.accountNumber || '',
            routingNumber: bank?.routingNumber || '',
            chequeCopy: null,
        });
    }, [bank]);

    const { accountHolder, bankName, branchName, accountNumber, routingNumber, chequeCopy } = adminBank;

    const onChange = (e) =>
        setAdminBank({
            ...adminBank,
            [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value,
        });

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        //console.log('Selected File:', selectedFile); // Log file details
        if(selectedFile !== undefined){
            setFileName(selectedFile.name);
            setAdminBank({
                ...adminBank,
                chequeCopy: selectedFile,
            });
        }else{
            setFileName(null);
            setAdminBank({
                ...adminBank,
                chequeCopy: null,
            });
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!adminBank.chequeCopy) {
            message.error('Insira a imagem do comprovante bancário!');
            return;
        }

        const isJpgOrPng = adminBank.chequeCopy.type === 'image/jpeg' || adminBank.chequeCopy.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Você só pode enviar arquivos JPG/PNG!');
            return;
        }

        setIsDisabled(true);
        
        try {
            const formData = new FormData();
            formData.append('doc', adminBank.chequeCopy);
            const result = await createFileBank(formData, user._id);
            
            if(result !== undefined){
                adminBank.chequeCopy = result.data.bank._id
                const body = JSON.stringify(adminBank)
                await updateAdminBank(body, user._id);
            }
            
        } catch (error) {
            message.error(error)
        }
        
        setIsDisabled(false);
    };

    return (
        <div className="col-md-12">
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Conta Bancária</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={onSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="accountHolder">Nome Completo</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="accountHolder"
                                    placeholder="nome completo"
                                    name="accountHolder"
                                    value={accountHolder}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="routingNumber">CPF/CNPJ</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="routingNumber"
                                    placeholder="CPF/CNPJ"
                                    name="routingNumber"
                                    value={routingNumber}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="bankName">Nome do banco</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="bankName"
                                    placeholder="nome do banco"
                                    name="bankName"
                                    value={bankName}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="branchName">Número da Agência</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="branchName"
                                    placeholder="número da agência"
                                    name="branchName"
                                    value={branchName}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="accountNumber">Número da Conta</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="accountNumber"
                                    placeholder="número da conta"
                                    name="accountNumber"
                                    value={accountNumber}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="chequeCopy">Comprovante Bancário</label>
                                <input
                                    type="file"
                                    className="form-control-file"
                                    id="chequeCopy"
                                    name="chequeCopy"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={isDisable}>
                            Enviar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

BankForm.propTypes = {
    bank: PropTypes.object,
    updateAdminBank: PropTypes.func.isRequired,
    createFileBank: PropTypes.func.isRequired,
    getAdminBank: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    bank: state.profile.bank,
});

export default connect(mapStateToProps, { updateAdminBank, getAdminBank, createFileBank })(BankForm);