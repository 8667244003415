import React, { useMemo } from 'react'
import SideBar from '../../common/SideBar'

const SuberadminBar = ({}) => {
    const titles = useMemo(()=>[
    {
        key:'admin',
        icon:'sliders',
        main: 'Dashboard',
        sub:[{
            name:'Gráficos',
            path:'/'
        }]
    }, 
    {
        key:'partner',
        icon:'bookmark',
        main: 'Lojas',
        sub: [{
            name: 'Adicionar Loja',
            path: '/superadmin/add-admin'
        },{
            name: 'Gestão de Lojas',
            path: '/superadmin/manage-admins'
        }]
    },
    {
        key:'user',
        icon: 'users',
        main: 'Usuários',
        sub: [{
            name: 'Gestão de Usuários',
            path: '/superadmin/manage-users'
        }]
    },
    {
        key:'product',
        icon: 'shopping-bag',
        main: 'Produtos',
        sub: [{
            name: 'Gestão de Produto',
            path: '/superadmin/manage-products'
        }]
    },
    {
        key:'banner',
        icon: 'map',
        main: 'Banner',
        sub: [{
            name: 'Adicionar Banner',
            path: '/superadmin/add-banner'
        },{
            name: 'Gestão de Banners',
            path: '/superadmin/manage-banners'
        }]
    }
        
    ],[])

    // phone, shopping-cart, chart-bar, map, shopping-bag, box, bookmark
    return (
        <SideBar titles={titles}/>
    )
}

export default (SuberadminBar)
