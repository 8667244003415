import React, { useState } from "react";
import { Button } from "antd";

function SigninForm({ handleChange, handleSubmit, state, loading }) {
  const [value, setValue] = useState({ hidden: true });

  const toggleShow = () => {
    setValue({ hidden: !value.hidden });
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}  autoComplete="on">
      <h1 className="text-center text-primary">Portal do Logista da Web Compras</h1>
      <div className="form-group">
        <input
          type="email"
          className="form-control"
          placeholder="email"
          name="email"
          autoComplete="on"
          onChange={handleChange}
          value={state.email}
          required
        />
      </div>

      <div className="form-group">
        <input
          type={value.hidden ? "password" : "text"}
          className="form-control"
          placeholder="password"
          autoComplete="on"
          name="password"
          onChange={handleChange}
          value={state.password}
          required
        />
        <div 
          className="illustration"
          onClick={toggleShow}
          style={{ cursor: "pointer", color: "#ffff" }}
        >
          {value.hidden ? (
            <i className="fa fa-eye-slash" />
          ) : (
            <i className="fa fa-eye" />
          )}
        </div>
      </div>
      

      <div className="form-group">
        <Button
          className="btn btn-primary btn-block"
          htmlType="submit"
          loading={loading}
        >
          Login
        </Button>
      </div>
    </form>
  );
}

export default SigninForm;
