import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updateBusinessInfo } from '../../../redux/actions/profile_actions'


const BusinessForm = ({user, updateBusinessInfo, loading}) => {
    const [profile, setProfile] = useState({
        name:'',
        dist:'',
        address:'',
        cpf:'',
        cnpj:'',
        identityFront:'',
        identityBack:'',
        license:'',
        holidayEnd:'',
    });
    // useEffect(()=>{
    //     user && setProfile({
    //         ...profile,
    //         name:user?.name?user.name:'',
    //         dist:user?.dist?user.dist:'',
    //         address:user?.address?user.address:'',
    //         cpf:user?.cpf?user.cpf:'',
    //         cnpj:user?.cnpj?user.cnpj:'',
    //         identityFront:user?.identityFront?user.identityFront:'',
    //         identityBack:user?.identityBack?user.identityBack:'',
    //     })
    // },[user])
   const {name,address,dist,cpf,cnpj,identityBack,identityFront,license} = profile
    
    const onChange = e => setProfile({...profile,[e.target.name]:e.target.value})
    const onSubmit = async e => {
        e.preventDefault()
        //updateProfile(profile,user._id)
        
    }
    return (
        <div className="col-md-12">
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Informação de negócios</h5>
                </div>
                <div className="card-body">
                    <form onSubmit={e=> onSubmit(e)}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputEmail4">Nome do proprietário</label>
                                <input type="text" className="form-control" id="inputEmail4" placeholder="Nome do proprietário" 
                                name='name'
                                value={name}
                                onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="add">Endereço</label>
                                <input type="text" className="form-control" id="add" placeholder="Endereço" 
                                name='address'
                                value={address}
                                onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="dist">Estado</label>
                                <input type="text" className="form-control" id="dist" placeholder="Estado" 
                                name='dist'
                                value={dist}
                                onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="cpf">CPF</label>
                                <input type="text" className="form-control" id="cpf" placeholder="CPF" 
                                name='cpf'
                                value={cpf}
                                onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="cnpj">CNPJ</label>
                                <input type="text" className="form-control" id="cnpj" placeholder="CNPJ" 
                                name='cnpj'
                                value={cnpj}
                                onChange={onChange}
                                />
                            </div>
                            {/* <div className="form-group col-md-6">
                                <label htmlFor="exampleFormControlFile1">Imagem da frente da identidade</label>
                                <input type="file" className="form-control-file" id="exampleFormControlFile1"
                                name='identityFront'
                                value={identityFront}
                                onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="exampleFormControlFile2">Imagem traseira da identidade</label>
                                <input type="file" className="form-control-file" id="exampleFormControlFile2" 
                                name='identityBack'
                                value={identityBack}
                                onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="exampleFormControlFile3">Cópia da licença comercial</label>
                                <input type="file" className="form-control-file" id="exampleFormControlFile3" 
                                name='license'
                                value={license}
                                onChange={onChange}
                                />
                            </div> */}
                        </div>
                        <button type="submit" className="btn btn-primary">Enviar</button>
                    </form>
                </div>
            </div>
        </div>

    )
}

// BusinessForm.propTypes = {

// }

//  export default BusinessForm

BusinessForm.propTypes = {
    BusinessInfo: PropTypes.object,
    updateBusinessInfo: PropTypes.func.isRequired,
    loading: PropTypes.bool,
}
const mapStateToProps = (state) => ({
    loading: state.auth.loading,
    user: state.auth.adminProfile,
})


export default connect(mapStateToProps,{updateBusinessInfo})(React.memo(BusinessForm))
