import React from 'react'
import ProductForm from './ProductForm'
import Layout from '../../../core/Layout'

const EditProduct = () => {
    return (
        <Layout>
            <ProductForm/>
        </Layout>

    )
}

export default (EditProduct)
