import React from 'react'

const Footer = props => {
    return (
        <>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row text-muted">
                        <div className="col-6 text-left">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <a className="text-muted" href="#!">Suporte</a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="text-muted" href="#!">Central de Ajuda</a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="text-muted" href="#!">Privacidade</a>
                                </li>
                                <li className="list-inline-item">
                                    <a className="text-muted" href="#!">Termos de Serviço</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 text-right">
                            <p className="mb-0">
                                &copy; {new Date().getFullYear()} - <a href="#" className="text-muted">Web Compras</a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
