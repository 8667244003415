import React from "react";
import { Switch, Route} from "react-router-dom";
import SuperAdminRoute from "./SuperAdminRoute";
import AdminRoute from './AdminRoute';

// UI components
import Home from "../components/pages/Home";
import Profile from "../components/pages/Profile";
import Product from '../components/pages/Product';
import AddProduct from '../components/pages/Product/AddProduct';
import EditProduct from '../components/pages/Product/EditProduct';
import Order from '../components/pages/Order';
import Admin from "../components/pages/SuperAdmin/Admin";
import User from "../components/pages/SuperAdmin/User";
import SProduct from "../components/pages/SuperAdmin/Product";
import AddAdmin from "../components/pages/SuperAdmin/AddAdmin";
import NotFound from '../components/pages/NotFound';
import Banner from '../components/pages/SuperAdmin/Banner';
import AddBanner from '../components/pages/SuperAdmin/AddBanner';
import UnderMaintenance from '../components/pages/UnderMaintenance';

const MainRouter = () => {
  return (
    <Switch>

      {/* Rotas comuns */}
      <Route exact path="/" component={Home} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/settings-privacy" component={UnderMaintenance} />
      <Route exact path="/help" component={UnderMaintenance} />
     
      {/* Rotas Admin */}
      <AdminRoute exact path="/add-product" component={AddProduct} />
      <AdminRoute exact path="/order" component={Order} />
      <AdminRoute exact path="/edit-product/:slug?" component={EditProduct} />
      <AdminRoute exact path="/manage-products" component={Product} />

      {/* Rotas Superadmin */}
      <SuperAdminRoute exact path="/superadmin/manage-products" component={SProduct} />
      <SuperAdminRoute exact path="/superadmin/add-admin" component={AddAdmin} />
      <SuperAdminRoute exact path="/superadmin/manage-admins" component={Admin} />
      <SuperAdminRoute exact path="/superadmin/manage-users" component={User} />
      <SuperAdminRoute exact path="/superadmin/manage-banners" component={Banner} />
      <SuperAdminRoute exact path="/superadmin/add-banner" component={AddBanner} />

      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default MainRouter;
